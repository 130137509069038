import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

export const ticketTypeActions = [
  'attendee_created',
  'attendee_rejected',
  'attendee_confirmed',
];

export const actionOptions = [
  // 'account_created', // this one can't be created
  // 'welcome_email', // for users, can't be created either
  // 'recover_password', // can't be created either
  ...ticketTypeActions,
];

export const templates = {
  essential: {
    Icon: DescriptionOutlinedIcon,
  },
  qrCode: {
    Icon: QrCodeIcon,
  },
  default: {
    Icon: InsertDriveFileOutlinedIcon,
  },
};

export const tagsByAction = (action) => {
  if (ticketTypeActions.includes(action))
    return ['attendeeTags', 'attendeeTypeTags', 'eventTags'];
  return ['eventTags'];
};

export const getAvailableTags = (
  action,
  tags,
  fields,
  t,
  currentLanguage,
  defaultLanguage
) => {
  const result = {};
  tagsByAction(action).forEach((tagGroup) => {
    const group = {
      name: t(`tagGroups.${tagGroup}`),
      mergeTags: {},
    };
    tags[tagGroup].default.forEach((tag) => {
      group.mergeTags[tag] = {
        name: t(`tags.${tag}`),
        value: `{{${tag}}}`,
        // sample: `placeholder${tag}`,
      };
    });
    tags[tagGroup].fields.forEach((tag) => {
      const fieldName = fields[tag.split('-f')[1]]?.name;
      group.mergeTags[tag] = {
        name: fieldName?.[currentLanguage] || fieldName?.[defaultLanguage],
        value: `{{${tag}}}`,
      };
    });
    result[tagGroup] = group;
  });
  return result;
};
