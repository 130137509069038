import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Button from 'eventtia-ui-components/lib/Button';
import Drawer, { DrawerContent } from 'eventtia-ui-components/lib/Drawer';
import { useNavigate, useOutletContext } from 'react-router-dom';
import formSchemas from '../../helpers/formSchemas';
import useCustomForm from '../../hooks/useCustomForm';
import { RHFDateInput, RHForm } from '../rhfWrappers';

const useStyles = makeStyles()((theme) => ({
  dates: {
    display: 'grid',
    gridTemplateRows: 'repeat(4, auto)',
    gridTemplateColumns: 'repeat(2, auto)',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(1.75),
    gridTemplateAreas: `
      'startFrom startTo'
      'clearStartDates .'
      'endFrom endTo'
      'clearEndDates .'
    `,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: 'repeat(6, auto)',
      gridTemplateColumns: 'repeat(1, auto)',
      gridTemplateAreas: `
      'startFrom'
      'startTo'
      'clearStartDates'
      'endFrom'
      'endTo'
      'clearEndDates'
    `,
    },
  },
  startFrom: {
    gridArea: 'startFrom',
  },
  startTo: {
    gridArea: 'startTo',
  },
  endFrom: {
    gridArea: 'endFrom',
  },
  endTo: {
    gridArea: 'endTo',
  },
  clearStartDates: {
    gridArea: 'clearStartDates',
    width: 'fit-content',
    height: 'fit-content',
    paddingLeft: theme.spacing(0),
    marginLeft: theme.spacing(-3.5),
    fontWeight: 'normal',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
      fontWeight: 'lighter',
    },
  },
  clearEndDates: {
    gridArea: 'clearEndDates',
    width: 'fit-content',
    height: 'fit-content',
    paddingLeft: theme.spacing(0),
    marginLeft: theme.spacing(-3.5),
    fontWeight: 'normal',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
      fontWeight: 'lighter',
    },
  },
}));

export const EventFilters = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('filters');
  const navigate = useNavigate();
  const { eventsFilters, setEventsFilters } = useOutletContext();

  const { filterActionsSchema } = formSchemas;
  const methods = useCustomForm(filterActionsSchema, {
    mode: 'onChange',
    defaultValues: eventsFilters,
  });
  const { setValue, reset } = methods;

  const onSubmit = (data) => {
    setEventsFilters({
      ...eventsFilters,
      ...data,
      'page[number]': 1,
    });
    navigate('..');
  };

  const clearStartDates = () => {
    setValue('startDateLowerBound', '');
    setValue('startDateUpperBound', '');
    setEventsFilters({
      ...eventsFilters,
      startDateUpperBound: '',
      startDateLowerBound: '',
    });
  };
  const clearEndDates = () => {
    setValue('endDateLowerBound', '');
    setValue('endDateUpperBound', '');
    setEventsFilters({
      ...eventsFilters,
      endDateUpperBound: '',
      endDateLowerBound: '',
    });
  };

  const dateFilters = (
    <div className={classes.dates}>
      <div className={classes.startFrom}>
        <RHFDateInput
          name="startDateLowerBound"
          label={t('dates.startFrom')}
          timePicker
        />
      </div>
      <div className={classes.startTo}>
        <RHFDateInput
          name="startDateUpperBound"
          label={t('dates.startTo')}
          timePicker
        />
      </div>
      <Button
        small
        variant="tertiary"
        className={classes.clearStartDates}
        onClick={clearStartDates}
      >
        {t('filters:clearDates')}
      </Button>
      <div className={classes.endFrom}>
        <RHFDateInput
          name="endDateLowerBound"
          label={t('dates.endFrom')}
          timePicker
        />
      </div>
      <div className={classes.endTo}>
        <RHFDateInput
          name="endDateUpperBound"
          label={t('dates.endTo')}
          timePicker
        />
      </div>
      <Button
        small
        className={classes.clearEndDates}
        variant="tertiary"
        onClick={clearEndDates}
      >
        {t('filters:clearDates')}
      </Button>
    </div>
  );

  const clearFiltersList = () => {
    reset({
      startDateUpperBound: '',
      startDateLowerBound: '',
      endDateUpperBound: '',
      endDateLowerBound: '',
    });
    setEventsFilters({});
  };

  return (
    <div className={classes.root}>
      <Drawer open anchor="right" handleClose={() => navigate('..')}>
        {({ DrawerContentProps }) => (
          <DrawerContent
            title={t('filters:title')}
            Wrapper={RHForm}
            WrapperProps={{
              rhfMethods: methods,
              'aria-label': t('ariaLabel.eventFilter'),
              autoComplete: 'off',
              onSubmit,
            }}
            primaryButton={
              <Button variant="primary" type="submit">
                {t('filters:filter')}
              </Button>
            }
            secondaryButton={
              <Button variant="tertiary" onClick={clearFiltersList}>
                {t('filters:clearFilters')}
              </Button>
            }
            {...DrawerContentProps}
          >
            {dateFilters}
          </DrawerContent>
        )}
      </Drawer>
    </div>
  );
};

export default EventFilters;
