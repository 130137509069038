export default {
  en: {
    apply: 'Apply filters',
    clear: 'Clear filters',
    eventPlaceholder: 'Search event name',
    filter: 'Filter',
    title: 'Filters',
    keywordTitle: 'Keyword search',
    ticketTitle: 'Ticket type',
    dateTitle: 'Date search',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    clearSearch: 'Clear search',
    clearDates: 'Clear dates',
    clearFilters: 'Clear filters',
    noTicketSelected: 'No ticket selected',
    search: 'Search',
    categories: {
      categoriesTypes: 'Categories types',
      all: 'All',
    },
    events: {
      datesRange: 'Date Range',
    },
    sort: {
      sortBy: 'Sort by',
      'first_name asc': 'Name A-Z↓',
      'first_name desc': 'Name Z-A↓',
      'last_name asc': 'Last name A-Z↓',
      'last_name desc': 'Last name Z-A↓',
      'company asc': 'Company A-Z↓',
      'company desc': 'Company Z-A↓',
    },
    chips: {
      name: 'Name: ',
      firstName: 'First name: ',
      lastName: 'Last name: ',
      email: 'Email: ',
      attendeeTypeId: 'Ticket: ',
      createdAtLowerBound: 'Starting from: ',
      createdAtUpperBound: 'Ending at: ',
      search: 'Search: ',
      startDateLowerBound: 'Starting from: ',
      startDateUpperBound: 'Starting before: ',
      endDateLowerBound: 'Ending from: ',
      endDateUpperBound: 'Ending by: ',
    },
    dates: {
      startFrom: 'Starting from',
      startTo: 'To',
      endFrom: 'Ended from',
      endTo: 'To',
    },
    ariaLabel: {
      attendeeFilter: 'Attendee filter',
      eventFilter: 'Events filter',
    },
  },
  es: {
    apply: 'Aplicar filtros',
    clear: 'Borrar filtros',
    title: 'Filtros',
    categories: {
      categoriesTypes: 'Tipos de categorias',
      all: 'Todas',
    },
    noFilterSelected: 'Ningún filtro seleccionado',
    events: {
      datesRange: 'Rango de Fechas',
    },
    sort: {
      sortBy: 'Ordenar por',
      'first_name asc': 'Nombre A-Z↓',
      'first_name desc': 'Nombre Z-A↓',
      'last_name asc': 'Apellido A-Z↓',
      'last_name desc': 'Apellido Z-A↓',
      'company asc': 'Compañia A-Z↓',
      'company desc': 'Compañia Z-A↓',
    },
  },
  fr: {
    apply: 'Appliquer les filtres',
    clear: 'Effacer les filtres',
    title: 'Filtres',
    categories: {
      categoriesTypes: 'Type de catégories',
      all: 'Tous',
    },
    noFilterSelected: 'Aucun filtre sélectionné',
    events: {
      datesRange: 'Plage de dates ',
    },
    sort: {
      sortBy: 'Trier par',
      'first_name asc': 'Prénom A-Z↓',
      'first_name desc': 'Prénom Z-A↓',
      'last_name asc': 'Nom A-Z↓',
      'last_name desc': 'Nom Z-A↓',
      'company asc': 'Entreprise A-Z↓',
      'company desc': 'Entreprise Z-A↓',
    },
  },
};
