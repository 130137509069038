export default {
  en: {
    title: 'Emails',
    senderSettings: 'Email sender settings',
    info: {
      createdByOn: 'Created by {{user}}. {{date}}',
    },
    messages: {
      emptyState:
        "You don't have any emails yet, to create one click on create email.",
      createdEmail: 'You created a new email!',
      deleteEmailConfirmation:
        "Are you sure you want to delete this email?\nAny associated communications will be reset to their default templates.\nThis action can't be undone.",
      unsavedChanges:
        'You have unsaved changes that will be lost if you switch to another language now. Do you want to proceed?',
    },
    actions: {
      createEmail: 'Create email',
      editSettings: 'Edit settings',
      createAnotherEmail: 'Create another email',
      editEmail: 'Edit email',
      duplicate: 'Duplicate',
      deleteEmail: 'Delete email',
      save: 'Save',
      configure: 'Configure',
    },
    actionOptions: {
      blank: 'Select a case',
      account_created: 'Account created',
      recover_password: 'Recover password',
      attendee_created: 'Attendee created',
      attendee_rejected: 'Attendee rejected',
      attendee_confirmed: 'Attendee confirmed',
      welcome_email: 'User welcome email',
    },
    actionAssignment: {
      blank: 'Default email',
      noEmailsCreated: "You haven't created emails for this action yet",
    },
    templateOptions: {
      default: 'Blank',
      essential: 'Essential',
      qrCode: 'QR code',
    },
    labels: {
      name: 'Email name',
      namePlaceholder: 'Name of email',
      description: 'Email description',
      action: 'Available for the following case',
      template: 'Select a template you would like to start with',
      subject: 'Subject',
      senderName: 'Sender name',
      senderNamePlaceholder: 'Ex: Get Togheter',
      senderEmail: 'Sender email',
      senderEmailPlaceholder: 'Ex: get-together@eventtia.com',
    },
    tagGroups: {
      eventTags: 'Event',
      attendeeTypeTags: 'Ticket Type',
      attendeeTags: 'Attendee',
    },
    tags: {
      title: 'Merge tags',
      'event-name': 'Event name',
      'event-description': 'Event description',
      'event-start_date': 'Event start date',
      'event-end_date': 'Event end date',
      'event-timezone': 'Event timezone',
      'event-location': 'Event location',
      'attendee_type-name': 'Ticket type name',
      'attendee_type-description': 'Ticket type description',
      'attendee-first_name': 'Attendee first name',
      'attendee-last_name': 'Attendee last name',
      'attendee-email': 'Attendee email',
      'attendee-alternative_email': 'Attendee alternative email',
      'attendee-telephone': 'Attendee telephone',
      'attendee-company': 'Attendee company',
      'attendee-birthdate': 'Attendee birthdate',
    },
  },
};
